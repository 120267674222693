var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1450px","persistent":"","scrollable":"","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.permissionCan('create'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('new')}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.quotation"))+" ")]):_vm._e()]}}]),model:{value:(_vm.modalData.dialog),callback:function ($$v) {_vm.$set(_vm.modalData, "dialog", $$v)},expression:"modalData.dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" ")]),_c('v-btn',{staticClass:"close-button",attrs:{"icon":""},on:{"click":_vm.handleCloseModalForm}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[(_vm.statuses.quotations)?_c('v-select',{attrs:{"items":_vm.statuses.pages.page,"label":_vm.$t('FORMS.status'),"item-value":"key"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t("STATUSES." + data.item.value))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t("STATUSES." + data.item.value))+" ")]}}],null,false,29268476),model:{value:(_vm.formModel.status),callback:function ($$v) {_vm.$set(_vm.formModel, "status", $$v)},expression:"formModel.status"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"customFieldName":"image","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .image || '',"config":{
                type: 'media_selector',
                maxFiles: 1,
                inputFields: [
                  {
                    name: 'alt_text',
                    label: 'FORM_INPUT_NAMES.alt_text',
                    type: 'text',
                  },
                  {
                    name: 'title',
                    label: 'FORM_INPUT_NAMES.title',
                    type: 'text',
                  } ],
                selectButtonText: 'FORM_INPUT_NAMES.select_image',
                initialColumnClass:
                  'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12',
              }},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{attrs:{"label":_vm.$t('FORMS.companyName'),"rules":_vm.nameRules,"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.company']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.company'] =
                  ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].company),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "company", $$v)},expression:"formModel.translations[selectedLocale.lang].company"}}):_vm._e(),(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('v-text-field',{attrs:{"label":_vm.$t('FORMS.name'),"rules":_vm.nameRules,"id":_vm.dynamicID,"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.name']},on:{"keyup":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.name'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].name),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "name", $$v)},expression:"formModel.translations[selectedLocale.lang].name"}}):_vm._e(),(
                _vm.formModel.translations[_vm.selectedLocale.lang] &&
                _vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
              )?_c('CustomFieldComponent',{attrs:{"config":{ type: 'text', label: _vm.$t('FORMS.position') },"customFieldName":"position","value":_vm.formModel.translations[_vm.selectedLocale.lang].custom_fields
                  .position},on:{"handleChangeCustomFieldValue":_vm.handleChangeCustomFieldValue}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.formModel.translations[_vm.selectedLocale.lang])?_c('ckeditor',{attrs:{"error-messages":_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'],"config":_vm.editorConfig},on:{"input":function($event){_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'] = ''}},model:{value:(_vm.formModel.translations[_vm.selectedLocale.lang].text),callback:function ($$v) {_vm.$set(_vm.formModel.translations[_vm.selectedLocale.lang], "text", $$v)},expression:"formModel.translations[selectedLocale.lang].text"}}):_vm._e(),(_vm.messages['translations.' + _vm.selectedLocale.lang + '.text'])?_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.messages["translations." + _vm.selectedLocale.lang + ".text"])+" ")]):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleCloseModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),(
          (_vm.permissionCan('create') && !_vm.formModel.id) ||
          _vm.permissionCan('update')
        )?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveModalForm}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1)],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }